export type ProductType = string;

export const PHOTOFRAME: ProductType = "photoFrame";
export const LENGTH: ProductType = "length";
export const CONTRACT: ProductType = "contract";
export const RAIL: ProductType = "rail";
export const BOX: ProductType = "box";
export const JOIN: ProductType = "join";
export const CHOP: ProductType = "chop";
export const CORNERSAMPLE: ProductType = "cornerSample";
export const MOULDING: ProductType = "moulding";
export const GALLERYFRAME: ProductType = "galleryFrame";
export const MIRROR: ProductType = "mirror";

//

export const productLabels = {
  [PHOTOFRAME]: "Photo Frame",
  [MOULDING]: "Moulding",
  [GALLERYFRAME]: "Gallery Frame",
  [MIRROR]: "Mirror",
  [LENGTH]: "Length",
  [JOIN]: "Join",
  [CHOP]: "Chop",
  [RAIL]: "Rail",
  [BOX]: "Box",
  [CORNERSAMPLE]: "Corner Sample",
  [CONTRACT]: "Contract",
};

export const productTypes = {
  [PHOTOFRAME]: 0,
  [MOULDING]: 1,
  [GALLERYFRAME]: 2,
  [MIRROR]: 3,
};

export const productTypeNumbers = [PHOTOFRAME, MOULDING, GALLERYFRAME, MIRROR];

export const hasProduct = (arr: string, sku: string, type = "moulding") => {
  if (!arr) return false;
  const key = JSON.stringify([sku, productTypes[type]]);
  return arr.includes(key);
};

export const serializeProducts = (
  input: string,
  sku: string,
  type: ProductType,
  action: "append" | "toggle",
  maxSize = 99
) => {
  const key = [sku, productTypes[type]];
  const arr = JSON.parse(input) || [];
  let location = -1;
  // Find the position in the array
  for (const i in arr) {
    if (arr[i][0] == key[0] && arr[i][1] == key[1]) {
      location = parseInt(i);
      break;
    }
  }
  // Add it to the beginning
  if (location === -1) {
    arr.unshift(key);
    // Remove or toggle
  } else {
    arr.splice(location, 1);
    if (action == "append") arr.unshift(key);
  }
  arr.slice(0, maxSize);
  return JSON.stringify(arr);
};

export const imageUrl = (
  sku: string,
  type = "moulding",
  num: number
): string => {
  let moulding = "";
  if (sku !== undefined) {
    moulding = sku;
  }
  let typePath = "mouldings";
  if (type === PHOTOFRAME) {
    typePath = "photo-frames";
  } else if (type === GALLERYFRAME) {
    typePath = "gallery-frames";
  }
  return `${import.meta.env.VITE_ROMA_CDN}/${typePath}/${moulding}-${num}.png`;
};

export const skuUrl = (sku: string, type = "moulding"): string => {
  let typePath = "";
  if (type === PHOTOFRAME) {
    typePath = "/photo-frame";
  } else if (type === GALLERYFRAME) {
    typePath = "/gallery-frame";
  } else if (type === MIRROR) {
    typePath = "/mirror";
  }
  return `/product/${sku}${typePath}`;
};
